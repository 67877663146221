import qs from "qs";

const localeMap: any = {
    "ja-JP": "jp",
    de: "de",
    es: "en",
};

class StrapiUtils {
    // static whiteList = [/\/.+/];
    static collectionWhiteList = [/^menu/, /^comparison/, /^common/];

    //数据转换
    static dataTramsform(data: any, path: any) {
        let result = null;
        if (Array.isArray(data) && data.length) {
            result = data[0].attributes?.source;
        } else {
            result = data.attributes?.source;
        }
        // console.log("🚀 -> file: db.ts:70 -> Strapi -> dataTramsform -> result:", result, path);
        return result;
    }

    //参数转换
    static getParamsFromPath(path: string) {
        path = qs.parse(path.split("?")[1], { ignoreQueryPrefix: false }).name as any;
        // if (path === "/") return ["home", {}] as const;
        const [model, params] = path.replace(/#+./, "").split("/");
        //如果model是驼峰形式转换为Snake形式
        let snakeModel = model.replace(/([A-Z])/g, "-$1").toLowerCase();
        if (StrapiUtils.collectionWhiteList.some((item) => item.test(snakeModel))) {
            snakeModel = snakeModel + "s";
        }
        // return [snakeModel, params ? { [`filters[model][$eq]`]: params } : {}] as const;
        return [snakeModel, params ? { model: params } : {}] as const;
    }

    static async packData(path: string, data: any, locale: string) {
        if (path === "/api/db?name=blog") {
            return StrapiUtils.getblogs(data, locale, 4);
        }
        if (path.startsWith("/api/db?name=blog&category=")) {
            const params = qs.parse(path.split("?")[1], { ignoreQueryPrefix: false });
            return StrapiUtils.getCategoryblogs(
                data,
                data.category.find((item) => new RegExp(params.category, "i").test(item.type)),
                locale,
                1000
            );
        }
        if (path === "/api/db?name=blog&pageSize=0") {
            return StrapiUtils.getblogs(data, locale, 1);
        } else if (path === "/api/db?name=help-center") {
            return StrapiUtils.getHelpCenter(data, locale);
        } else if (path === "/api/db?name=warranty") {
            return StrapiUtils.getWarranty(data, "warranty", locale);
        } else if (path === "/api/db?name=terms-of-service") {
            return StrapiUtils.getWarranty(data, "terms-of-service", locale);
        } else if (path === "/api/db?name=privacy-statement") {
            return StrapiUtils.getWarranty(data, "3", locale);
        } else if (path === "/api/db?name=return-refund-policy") {
            return StrapiUtils.getWarranty(data, "return-refund-policy", locale);
        } else if (path === "/api/db?name=shipping-policy") {
            return StrapiUtils.getWarranty(data, "shipping-policy", locale);
        } else if (path === "/api/db?name=buyer-faq") {
            return StrapiUtils.getBuyerFaq(data, "0", locale);
        } else {
            return data;
        }
    }

    //兼容从blog迁移过来的数据
    static async getblogs(data: any, locale: string, pageSize?: number) {
        const categoryArr = data.category.filter((item) => item.show !== false).map((item: any) => item.value || item.type);
        const category = (categoryArr || []).concat([data.banner.category]).join(",");
        const lang = localeMap[locale] || "en";
        const {
            data: {
                value: { result = [] },
            },
        } = await useFetchWithBaseUrl(`/api/post?type=2&region=${lang}&category=${category}&fallback=en&pageSize=${pageSize ?? 10}`);

        const categoryPost = (categoryArr as string[]).reduce((prev, item) => {
            prev[item] = result.filter((post: any) => post.category === item);
            if (prev[item].length === 0) {
                delete prev[item];
            }
            return prev;
        }, {} as { [key: string]: any });

        const feature = result.find((item: any) => item.category === data.banner.category);

        return { ...data, posts: result, categoryPost, feature };
    }

    //兼容从blog迁移过来的数据
    static async getCategoryblogs(data, category: any, locale: string, pageSize?: number) {
        const lang = localeMap[locale] || "en";
        const {
            data: {
                value: { result = [] },
            },
        } = await useFetchWithBaseUrl(`/api/post?type=2&region=${lang}&category=${category.type}&fallback=en&pageSize=${pageSize ?? 10}`);

        return { ...data, category, posts: result };
    }

    //兼容从help-center迁移过来的数据
    static async getHelpCenter(data: any, locale: string) {
        const category = data.category || [];

        const {
            data: {
                value: { result = [] },
            },
        } = await useFetchWithBaseUrl(`/api/post?type=1&category=${category}&region=${locale}&fallback=en`);

        const categoryPost = (data.category as string[]).reduce((prev, item) => {
            prev[item] = result.filter((post: any) => post.category === item);
            if (prev[item].length === 0) {
                delete prev[item];
            }
            return prev;
        }, {} as { [key: string]: any });

        return { ...data, posts: result, categoryPost };
    }

    //兼容warranty迁移过来的数据
    static async getWarranty(data: any, type: string, locale: string) {
        const {
            data: {
                value: { result = [] },
            },
        } = await useFetchWithBaseUrl(`/api/post?type=${type}&region=${locale}&fallback=en`);

        const {
            data: {
                value: { result: detail },
            },
        } = await useFetchWithBaseUrl(`/api/post/path/${result[0].path}`);

        return { ...data, ...detail };
    }

    //兼容warranty迁移过来的数据
    static async getBuyerFaq(data: any, type: string, locale: string) {
        const {
            data: {
                value: { result = [] },
            },
        } = await useFetchWithBaseUrl(`/api/post?type=${type}&region=${locale}&fallback=en`);

        const {
            data: {
                value: { result: detail },
            },
        } = await useFetchWithBaseUrl(`/api/post/path/${result[0].path}`);

        return { ...data, post: detail ?? null };
    }
}

/**设定一个请求方法，通过strapi获取数据 */
export async function useStrapiWithLocale<T = any>(path: string): Promise<T> {
    try {
        const { locale } = useI18n();
        const { find } = useStrapi();

        const [model, filters] = StrapiUtils.getParamsFromPath(path);

        // 如果是menu、comparison、common这三个模型，则判断第一项有没有值，如果没有值就使用英文数据
        // 如果当前语言没有数据，就使用英文数据
        let data = null;
        try {
            const res = await find(model, { filters, locale: locale.value as any });
            if (StrapiUtils.collectionWhiteList.some((item) => item.test(model))) {
                if (!res.data[0]) {
                    throw new Error("no data");
                }
            }
            data = res.data;
        } catch (error) {
            const res = await find(model, { filters, locale: "en" as any });
            data = res.data;
        }

        const result = StrapiUtils.dataTramsform(data, path);
        return StrapiUtils.packData(path, result, locale.value);
    } catch (error) {
        useRouteCache((v) => v.setUncacheable());
        throw error;
    }
}
